import React, { useEffect, useRef, useState, lazy, Suspense } from "react";
import './App.css';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Resume Builder Components
import FormComponent from './FormComponent';
import FormComponent_2 from './FormComponent_2';
import FormComponent_3 from './FormComponent_3';
import ResHero from './components/ResHero';
import Resume from './components/Template1/Resume';
import Resume2 from './components/Template2/Resume2';
import Resume3 from './components/Template3/Resume3';
import Resume4 from './components/Template4/Resume4';
import Resume5 from './components/Template5/Resume5';
import Resume6 from './components/Template6/Resume6';
import Resume7 from './components/Template7/Resume7';
import Resume8 from './components/Template8/Resume8';
import Resume9 from './components/Template9/Resume9';
import Resume10 from './components/Template10/Resume10';
import { ResumeDataProvider } from './components/Context/ResumeContext';
import ChooseTemplate from './components/ChooseTemplate';
import SearchedOutput from './pages/SearchedOutput';

import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

// Lazy loaded components
const Footer = lazy(() => import('./components/Footer'));
const Recognition = lazy(() => import('./components/Recognition'));
const Aws_Course_Details_Page = lazy(() => import('./pages/Aws_Course_Details_Page'));
const Python_Course_Details_Page = lazy(() => import('./pages/Python_Course_Details_Page'));
const Mysql_Course_Details_Page = lazy(() => import('./pages/Mysql_Course_Details_Page'));
const E_Book_2024 = lazy(() => import('./pages/E_Book_2024'));
const Streamyardpage = lazy(() => import('./pages/Streamyardpage'));
const TestimonialsPage = lazy(() => import('./pages/TestimonialsPage'));
const Ebook = lazy(() => import('./pages/Ebook'));
const Survey = lazy(() => import('./pages/Survey'));
const Courses = lazy(() => import('./pages/Courses'));
const Webinar = lazy(() => import('./components/Webinar/Webinar'));
const Hero = lazy(() => import('./components/Webinar/Hero'));
const Hero2 = lazy(() => import('./components/Webinar/Hero2'));
const TestimonialFreshers = lazy(() => import('./pages/TestimonialFreshers'));
const TestimonialExperienced = lazy(() => import('./pages/TestimonialExperienced'));
const Cloud_PlacementDrive_Form = lazy(() => import('./pages/Cloud_PlacementDrive_Form'));
const Offcampus_PlacementDrive_Form = lazy(() => import('./pages/Offcampus_PlacementDrive_Form'));
const Testimonials = lazy(() => import('./pages/Testimonials'));
const YTFullCoursePage = lazy(() => import('./pages/YTFullCoursePage'));
const Legal = lazy(() => import('./pages/Legal'));
const Whatsapp = lazy(() => import('./pages/Whatsapp'));
const Kn_Blogs = lazy(() => import('./pages/Kn_Blog'));
const JoinDemo = lazy(() => import('./pages/JoinDemo'));
const Task_Steps = lazy(() => import('./pages/Task_Steps'));
const Privacy = lazy(() => import('./pages/Privacy'));
const BankDetails = lazy(() => import('./pages/BankDetails'));
const ThankYou = lazy(() => import('./pages/ThankYou'));
const Thank_You = lazy(() => import('./pages/Thank_You'));
const AWS_FAQ = lazy(() => import('./pages/AWS_FAQ'));
const Demo_Form = lazy(() => import('./pages/Demo_Form'));
const AWS_DevOps = lazy(() => import('./pages/AWS_DevOps'));
const FreeTrial = lazy(() => import('./pages/Free_Trial'));
const Zoom_Link_AWS = lazy(() => import('./pages/Zoom_Link_AWS'));
const Webinar_Youtube_Live = lazy(() => import('./pages/Webinar_Youtube_Live'));
const Webinar_registration = lazy(() => import('./pages/Webinar_Registration'));
const Company_Vacancies = lazy(() => import('./pages/Company_Vacancies'));
const Webinar_Link = lazy(() => import('./pages/Webinar_Link'));
const E_Book = lazy(() => import('./pages/E_Book'));
const EBook_SheetFill = lazy(() => import('./pages/EBook_SheetFill'));
const Ship_Delivery_Policy = lazy(() => import('./pages/Ship_Delivery_Policy'));
const E_Book_Aug18 = lazy(() => import('./pages/E_Book_Aug18'));
const AWS_DevOps_Curriculm = lazy(() => import('./pages/AWS_DevOps_Curriculum'));
const AWS_Demo_Link = lazy(() => import('./pages/AWS_Demo_Link'));
const PaymentLink = lazy(() => import('./pages/PaymentLink'));
const Devopsforkannadigas = lazy(() => import('./pages/Devopsforkannadigas'));
const Pythonforkannadigas = lazy(() => import('./pages/Pythonforkannadigas'));
const PowerBi = lazy(() => import('./pages/PowerBi'));
const Java_at = lazy(() => import('./pages/Java-at'));
const Topper_of_the_month = lazy(() => import('./pages/Topper_of_the_month'));
const PythonQuiz = lazy(() => import('./pages/PythonQuiz'));
const Awsdevops = lazy(() => import('./components/Aws & DevOps/Awsdevops'));
const Pythonfullstack = lazy(() => import('./components/Python-Fullstack/Pythonfullstack'));
const Pythonfullstack2 = lazy(() => import('./components/Python-Fullstack-New/Pythonfullstack'));
const DockerAndKubernetesCertificationPage = lazy(() => import('./pages/DockerAndKubernetesCertificationPage'));
const Offcampus_PlacementDrive_Jan2025 = lazy(() => import('./pages/Offcampus_PlacementDrive_Jan2025'));
// const Pythontesting = lazy(() => import('./pages/Pythontesting'));

function App() {
  const [searchedResults, setSearchedResults] = useState([]);
  const [alertStatus, setAlertStatus] = useState(false);

  const handleAlert = () => {
    setAlertStatus(!alertStatus);
    setTimeout(() => (
      setAlertStatus(false)
    ), 4000);
  };

  const ref = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      ref.current?.click();
    }, 1000);
  }, []);

  return (
    <div className="App relative w-full h-full overflow-x-hidden overflow-y-hidden font-poppins">
      <div className='absolute top-0 bg-[#f9f8fd] -z-10 w-full h-full'>
        <div className='relative h-full'></div>
      </div>

      <div className='overflow-x-hidden w-full h-full relative'>
        {/* Alert Component */}
        <div className={`${alertStatus ? "block" : 'hidden'} fixed z-40 w-full h-screen flex justify-center items-end py-20 sm:items-end sm:py-5`}>
          <div className="bg-green-500 rounded-b text-white px-4 py-1 shadow-md" role="alert">
            <div className="flex">
              <div className="py-1">
                <svg className="fill-current h-6 w-6 text-white mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/>
                </svg>
              </div>
              <div className='flex flex-col justify-center items-start'>
                <p className="font-medium">Submitted Successfully</p>
                <p className="font-light text-xs xl:text-sm">Our Team will contact you shortly</p>
              </div>
            </div>
          </div>
        </div>

        <Router>
          <Navbar setSearchedResults={setSearchedResults} />
          <ResumeDataProvider>
            <Suspense fallback={<h1>Loading...</h1>}>
              <Routes>
                {/* Home Route with Search Results */}
                {searchedResults.length !== 0 
                  ? <Route exact path="/" element={<SearchedOutput searchedResults={searchedResults} setSearchedResults={setSearchedResults} />} />
                  : <Route exact path="/" element={<Home handleAlert={handleAlert} />} />
                }

                {/* Resume Builder Routes */}
                <Route path="/microresume" element={<ResHero />} />
                <Route path="/create-resume" element={<FormComponent />} />
                <Route path="/create-resume2" element={<FormComponent_2 />} />
                <Route path="/create-resume3" element={<FormComponent_3 />} />
                <Route path="/choose-template" element={<ChooseTemplate />} />
                <Route path="/resume/Template1" element={<Resume />} />
                <Route path="/resume/Template2" element={<Resume2 />} />
                <Route path="/resume/Template3" element={<Resume3 />} />
                <Route path="/resume/Template4" element={<Resume4 />} />
                <Route path="/resume/Template5" element={<Resume5 />} />
                <Route path="/resume/Template6" element={<Resume6 />} />
                <Route path="/resume/Template7" element={<Resume7 />} />
                <Route path="/resume/Template8" element={<Resume8 />} />
                <Route path="/resume/Template9" element={<Resume9 />} />
                <Route path="/resume/Template10" element={<Resume10 />} />

                {/* Other Application Routes */}
                <Route exact path='/Recognition' element={<Recognition />} />
                <Route exact path='/Aws_Course_Details_Page' element={<Aws_Course_Details_Page />} />
                <Route exact path='/Python_Course_Details_Page' element={<Python_Course_Details_Page />} />
                <Route exact path='/Mysql_Course_Details_Page' element={<Mysql_Course_Details_Page />} />
                <Route exact path='/e_book_2024' element={<E_Book_2024 />} />
                <Route exact path='/streamyardpage' element={<Streamyardpage />} />
                <Route exact path='/TestimonialFreshers' element={<TestimonialFreshers />} />
                <Route exact path='/TestimonialExperienced' element={<TestimonialExperienced />} />
                <Route exact path='/TestimonialsPage' element={<TestimonialsPage />} />
                <Route exact path='/ebook' element={<Ebook />} />
                <Route exact path='/survey' element={<Survey />} />
                <Route exact path='/courses' element={<Courses />} />
                <Route exact path='/whatsapp' element={<Whatsapp />} />
                <Route exact path='/kn_blogs' element={<Kn_Blogs />} />
                <Route exact path='/joindemo' element={<JoinDemo />} />
                <Route exact path='/aws_faq' element={<AWS_FAQ />} />
                <Route exact path='/demo_form' element={<Demo_Form />} />
                <Route exact path='/aws_devops' element={<AWS_DevOps />} />
                <Route exact path='/bankdetails' element={<BankDetails />} />
                <Route exact path='/thankyou' element={<ThankYou />} />
                <Route exact path='/thank_you' element={<Thank_You />} />
                <Route exact path='/refund-and-course-rescheduling-policy' element={<Privacy />} />
                <Route exact path='/task_steps' element={<Task_Steps />} />
                <Route exact path='/webinar_youtube_live' element={<Webinar_Youtube_Live />} />
                <Route exact path='/webinar_registration' element={<Webinar_registration />} />
                <Route exact path='/company_vacancies' element={<Company_Vacancies />} />
                <Route exact path='/webinar_link' element={<Webinar_Link />} />
                <Route exact path='/e_book' element={<E_Book />} />
                <Route exact path='/e_book_sheet_fill' element={<EBook_SheetFill />} />
                <Route exact path='/ship-and-delivery-policy' element={<Ship_Delivery_Policy />} />
                <Route exact path='/aws_devops_curriculm' element={<AWS_DevOps_Curriculm />} />
                <Route exact path='/aws_demo_link' element={<AWS_Demo_Link />} />
                <Route exact path='/webinar' element={<Webinar />} />
                <Route exact path='/Cloud_PlacementDrive_Form' element={<Cloud_PlacementDrive_Form />} />
                <Route exact path='/Offcampus_PlacementDrive_Form' element={<Offcampus_PlacementDrive_Form />} />
                <Route exact path='/Testimonials' element={<Testimonials />} />
                <Route exact path='/YTFullCoursePage' element={<YTFullCoursePage />} />
                <Route exact path='/Legal' element={<Legal />} />
                <Route exact path='/bookyourseat' element={<PaymentLink />} />
                <Route exact path='/devopsforkannadigas' element={<Devopsforkannadigas />} />
                <Route exact path='/pythonforkannadigas' element={<Pythonforkannadigas />} />
                <Route exact path='/PowerBi' element={<PowerBi />} />
                <Route exact path='/java-at' element={<Java_at />} />
                <Route exact path='/topper-of-the-month' element={<Topper_of_the_month />} />
                <Route exact path='/pythonquiz' element={<PythonQuiz />} />
                <Route exact path='/Awsdevops' element={<Awsdevops />} />
                <Route exact path='/Pythonfullstack' element={<Pythonfullstack />} />
                <Route exact path='/Pythonfull-stack' element={<Pythonfullstack2 />} />
                <Route exact path='/dockerandkubernetes' element={<DockerAndKubernetesCertificationPage />} />
                <Route exact path='/offcampus_placementdrive_jan2025' element={<Offcampus_PlacementDrive_Jan2025 />} />
                {/* <Route exact path='/pythontesting' element={<Pythontesting />} /> */}
              </Routes>
            </Suspense>
          </ResumeDataProvider>
          <Footer />
        </Router>
      </div>
    </div>
  );
}

export default App;